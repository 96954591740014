export const projectList = [
  {
    id:'m1',
    slug: '/capstone', 
    image:require('../../assets/projects/capstone-main.png'),
    title: 'NextBlock.shop',
    description: 'E-commerce platform for local side businesses.',
    descriptionExtended: 'Full stack project using React, Node, Express, MySQL & AWS, enabling small businesses to create their own webpages with special features to support side hustles. Fully deployed and operational.',
    stack: ["Javascript", "React", "Node", "Express", "SQL", "Design", "AWS"]
  },
  {
    id:'m2',
    slug: '/lounge',
    image:require('../../assets/projects/lounge-main.jpg'),
    title: 'Interactive Room',
    description: 'Hardware + software prototype for interactive partitions',
    descriptionExtended: 'Test of concept for an interactive space with 4 distinct modes of interaction (human-like, direct manipulation, discreet self-adjusting, emerging response)',
    stack: ["Python", "Javascript", "Web Sockets", "Design"]
  },
  {
    id:'m3',
    slug: '/legacy',
    image:require('../../assets/projects/legacy-main.jpg'),
    title: 'Bike Alive',
    description: 'Hardware + software prototype for bike companion',
    descriptionExtended:'Bike add-ons that emulate horse behaviour cues. Built with Arduino, servo motors & Android Studio for HCI research.',
    stack: ["Arduino"]
  },
  {
    id:'m12',
    slug:'/moodyAI',
    image:require('../../assets/projects/moodyAI.JPG'),
    title:'Moody AI',
    description:'Full stack application for amusing chatbot with 4 moods to select from',
    descriptionExtended:'React + TypeScript & Python + Django application using OpenAI API. With user authentication, it records responses in SQL database and makes it easy to find past responses through favorites & filters.',
    stack: ["Typescript", "React", "Python", "Django", "Design", "SQL"]
  },
  {
    id:'m4',
    slug:'/pokemon',
    image:require('../../assets/projects/poke-main.JPG'),
    title:'Drunk Pokemon Battle',
    description:'24h hackaton game using 2 external RESTful apis',
    stack: ["Javascript"]
  },
  {
    id:'m5',
    slug:'/wordle',
    image:require('../../assets/projects/wordle-main.JPG'),
    title:'Wordle, expanded',
    description:'24h hackaton full stack application with React and Express',
    stack: ["Javascript", "React", "Node", "Express"]
  },
  {
    id:'m6',
    slug:'/vr',
    image:require('../../assets/projects/unity-main.png'),
    title:'Interactive building in VR',
    description:'VR project built using Unity engine and Oculus SDK',
    stack: ["Unity", "Design"]
  },
  {
    id:'m7',
    slug:'/pinball',
    image:require('../../assets/projects/portfolio-thumb.png'),
    title:'This portfolio',
    description:'Pinball inspired portfolio page',
    stack: ["Javascript", "React"]
  },
  {
    id:'m8',
    slug:'/algorithmic',
    image:require('../../assets/projects/alg-thumb.JPG'),
    title:'Algorithmic Design',
    description:'Selected projects',
    descriptionExtended:'Projects using algorithms to generate design, form and/or production output',
    stack: ["Grasshopper", "Design"]
  },
  {
    id:'m9',
    slug:'/ev',
    image:require('../../assets/projects/ev-thumb.jpg'),
    title:'Smart microgrid interface',
    description:"BCIT EV charging stations' user interface",
    descriptionExtended:"Research on persuasive interfaces for renewable energy optimization, implemented on BCIT' Energy Oasis",
    stack: ["Design"]
  },
  {
    id:'m10',
    slug:'/webpages',
    image:require('../../assets/projects/pages-thumb.JPG'),
    title:'A few more webpages',
    description:"Some webpages I've worked on along the way",
    stack: ["Javascript", "React", "Node", "Express"]
  },
  {
    id:'m11',
    slug:'/architecture',
    image:require('../../assets/projects/arch-main.JPG'),
    title:'A few buildings',
    description:'Architectural, Interiors and Urban Design work.',
    descriptionExtended:'Academic and professional architectural portfolios.',
    stack: ["Design"]
  }
]

export const projectFilters = [...new Set(projectList.reduce((previousValue, currentValue) => previousValue.concat(currentValue.stack), []))];