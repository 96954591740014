import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Menu from './Components/Menu/Menu';
import Landing from './pages/Landing/Landing';
import NotFound from './pages/NotFound/NotFound';
// import About from './pages/About/About';
import Mobile from './pages/Mobile/Mobile';
import Capstone from './pages/Projects/Capstone';
import InteractiveRoom from './pages/Projects/InteractiveRoom';
import Legacy from './pages/Projects/Legacy';
import MoodyAI from './pages/Projects/MoodyAI';
import Pokemon from './pages/Projects/Pokemon';
import Wordle from './pages/Projects/Wordle';
import VR from './pages/Projects/VR';
import Pinball from './pages/Projects/Pinball';
import Algorithmic from './pages/Projects/Algorithmic';
import EV from './pages/Projects/EV';
import Architecture from './pages/Projects/Architecture';
import Webpages from './pages/Projects/Webpages';

function App() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    };
  }, [])

  return (
    <BrowserRouter>
      <main className="main">
        {isMobile
        ? <Mobile />
        : <>
          <Menu />
          <section className="main__pages">
            <Routes>
              <Route path="/" element={<Landing/>} />
              {/* <Route path='/about' element={<About/>} /> */}
              <Route path='/capstone' element={<Capstone/>} />
              <Route path='/lounge' element={<InteractiveRoom/>} />
              <Route path='/legacy' element={<Legacy/>} />
              <Route path='/moodyAI' element={<MoodyAI/>} />
              <Route path='/pokemon' element={<Pokemon/>} />
              <Route path='/wordle' element={<Wordle/>} />
              <Route path='/vr' element={<VR/>} />
              <Route path='/pinball' element={<Pinball/>} />
              <Route path='/algorithmic' element={<Algorithmic/>} />
              <Route path='/ev' element={<EV/>} />
              <Route path='/webpages' element={<Webpages/>} />
              <Route path='/architecture' element={<Architecture/>} />
              <Route path='/*' element={<NotFound/>} />
            </Routes>
          </section>
        </>
        }
      </main>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
