import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import moodyHero from '../../assets/projects/moodyAI.JPG';
import moodyAxe from '../../assets/projects/moodyAI-axe.JPG';



const projectSummary = {
    title: "Moody AI",
    description: "interface for interaction with an AI chatbot, where the user can select the chatbot's mood.",
    stack: "React, Typescript, Python, Django",
    team: "Sara Maia (solo)",
    role: "Code, design",
    year: "2022",
    repo: "https://github.com/scmaia/MoodyAI-FE",
    final_text: "https://moodyai.netlify.app/",
    final_link: "https://moodyai.netlify.app/"
}

function MoodyAI() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={moodyHero} fit='width'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <ul>
                        <li>User authentication</li>
                        <li>Mood selector</li>
                        <li>Response training in the backend appended to external API request </li>
                        <li>Option to favorite responses</li>
                        <li>Filter per mood and/or favorites</li>
                        <li>Unit testing</li>
                        <li>Tested for accessibility with Axe</li>
                        <li>Frontend stack: Typescript & React</li>
                        <li>Backend stack: Python, Django & Django Rest Framework</li>
                    </ul>
                </div>
                <div className='project__subsection'>
                    <div className='project__container'>
                        <img src={moodyAxe} className='project__img-full' alt='screenshot of test passed'/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MoodyAI;