import './AboutItem.scss';

function AboutItem({ avatar, cv }) {
    
    return (
        <div className='about'>
            <div className='about__container'>
                <img className='about__avatar' src={avatar}/>
                <a href={cv} className='bar__link' target="_blank" rel="noreferrer"><p className='about__bubble'>About me</p></a>
            </div>
            <div className='about__divider'/>
        </div>
    );
};

export default AboutItem;