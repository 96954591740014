import './Header.scss';
import title from '../../assets/images/ttitle.svg'
import pipe from '../../assets/images/pipe.svg'


function Header({ totalPins, hitPins, cv}) {
    
    return (
        <>
        <header className='header'>
            <div className='header__section'>
                <img src={pipe} className='header__pipe' alt=''/>
            </div>
            <div className='header__section' />
            <div className='header__section'>
                {/* <div className='header__title'>
                    <img src={title} alt="Sara Maia's work sample"/>
                    <a href={cv} className='header__underline'> See resume</a>
                </div> */}
                <div className='header__score'>
                    <h3>PINS HIT: <span className='header__digits'>{hitPins ? hitPins : '0'}</span> of <span className='header__digits'>{totalPins ? totalPins : ''}</span></h3>
                    <p className='header__instructions-expanded'> {'>'} hover for instructions</p>
                    <div className='header__instructions-collapsed'>
                        {/* <p>⭯ Drag landing bar to change angle and redirect ball</p> */}
                        <p>🖰 Hover project pins to "cap" them and let ball roll over</p>
                        <p>🖮 Hit shift to control flippers at bottom of page</p>
                    </div>
                </div>
            </div>
        </header>
        <div>
            <div className='header__container'>
                <div className='header__titles'>
                    <h1>Hi, I'm Sara!</h1>
                    <a href={cv} className='header__underlines'> See resume</a>
                </div>
            </div>
            <h2 className='header__subtitle'>This is some of my work</h2>
        </div>
        </>
    );
};

export default Header;