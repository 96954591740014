import './Flippers.scss';
import leftFlipper from '../../assets/images/flipper-left.svg';
import rightFlipper from '../../assets/images/flipper-right.svg';

function Flippers({ leftActivated, rightActivated }) {
    
    return (
        <div className='flippers'>
            <h2>SHIFT__</h2>
            <img className={leftActivated ? 'flippers__flipper flippers__activeLeft' : 'flippers__flipper'} id='flipper-left' src={leftFlipper} alt='left flipper'/>
            <img className={rightActivated ? 'flippers__flipper flippers__activeRight' : 'flippers__flipper'} id='flipper-right' src={rightFlipper} alt='right flipper'/>
            <h2>__SHIFT</h2>
        </div>
    );
};

export default Flippers;