import './Mobile.scss';

function Mobile() {
    return (
        <div className='mobile'>
            <h1>Hi there!</h1>
            <p className='not-found__text'>Sorry, this page is not available on mobile yet. Please access using tablet, laptop or desktop device.</p>
        </div>
    );
};

export default Mobile;