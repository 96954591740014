import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import pageHero from '../../assets/projects/pages-thumb.JPG';
import pageImg1 from '../../assets/projects/pages-1.JPG';
import pageImg2 from '../../assets/projects/pages-2.JPG';


const projectSummary = {
    title: "Some webpages",
    description: "A few more webpages I can think of",
    stack: "HTML, SASS, Javascript",
    team: "Sara Maia",
    role: "Code",
    year: "Various",
    repo: "Not public",
    final_text: "Not published",
    final_link: ""
}

function Webpages() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={pageHero} fit='width'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>In stock</h2>
                    <p>Deployed: <a href='https://ancient-gorge-46150.herokuapp.com/'>https://ancient-gorge-46150.herokuapp.com/</a></p>
                    <p>Frontend Repo: <a href='https://github.com/nicholas-hucal/instock-belle'>https://github.com/nicholas-hucal/instock-belle</a></p>
                    <p>Backend Repo: <a href='https://github.com/nicholas-hucal/instock-api-belle'>https://github.com/nicholas-hucal/instock-api-belle</a></p>
                    <div className='project__container'>
                        <p className='project__overview'>Group project in React and Express for inventory management application. Developed as part of Brainstation's web development diploma coursework.</p>
                        <img src={pageImg1} className='project__img-half' alt='rendering'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>Brainflix</h2>
                    <div className='project__container'>
                        <p className='project__overview'>React application for video uploading and streaming. Developed as part of Brainstation's web development diploma coursework.</p>
                        <img src={pageHero} className='project__img-half' alt='rendering'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>Research page, diary & blog</h2>
                    <p>Research page: <a href='http://interacting.space/room'>http://interacting.space/room/</a></p>
                    <p>Diary: <a href='http://interacting.space/diary'>http://interacting.space/diary/</a></p>
                    <p>Blog: <a href='http://interacting.space'>http://interacting.space</a></p>
                    <div className='project__container'>
                        <p className='project__overview'>Wepages build using bootstrap templates & javascript</p>
                        <img src={pageImg2} className='project__img-half' alt='rendering'/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Webpages;