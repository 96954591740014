import { useEffect } from 'react';
import './NotFound.scss';

function NotFound() {
    useEffect( () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });;
    }, [])
    
    return (
        <div className='not-found'>
            <h1>404: Page not found</h1>
            <p className='not-found__text'>Sorry, the page that you're looking for does not exist (yet?)</p>
        </div>
    );
};

export default NotFound;