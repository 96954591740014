import './MenuItem.scss';
import { useLocation } from "react-router-dom";

function MenuItem({ image, title, description, descriptionExtended, setHoveredItem, id, hit }) {
    const currentPath = useLocation().pathname;
    
    const handleMouseEnter = (event) => {
        setHoveredItem(event.currentTarget)
    }

    const handleMouseLeave = () => {
        setHoveredItem(null)
    }

    return (
        <>
        <div id={id} className={currentPath !== '/' ? 'item__list' : 'item'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`item__image ${currentPath !== '/' && 'item__image--list'}`} style={{backgroundImage: `url(${image})`}}/>
            <div className='item__information'>
                <div className='item__overlay'/>
                <p className='item__title'>{title}</p>
                <p className='item__description'>{description}</p>
                <p className='item__description item__description--extended'>{descriptionExtended}</p>
            </div>
        </div>
        {hit && currentPath === '/' &&
            <p className='item__hit'>HIT!</p>
        }
        </>
    );
};

export default MenuItem;