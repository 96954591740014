import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import wordleHero from '../../assets/projects/wordle-main.JPG';


const projectSummary = {
    title: "Wordle, Kinda",
    description: "24 Hackaton project. We created a copy of wordle with basis for expanding on its features",
    stack: "React",
    team: "Sara Maia, James Truong",
    role: "Front-end code",
    year: "2022",
    repo: "https://github.com/scmaia/wordle-clone-react",
    final_text: "https://wordle-kinda.herokuapp.com/",
    final_link: "https://wordle-kinda.herokuapp.com/" 
}

function Wordle() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={wordleHero} fit='width'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <div className='project__container'>
                        <p className='project__overview'>For the 24h hackaton, me and James chose to create a Wordle clone with built-in room for customization and expansion. The clone is now deployed and currently allows you play several times a day to your heart's desire. Version 2 of this application with all the new bells and whistles will come out soon!</p>
                        <img src={wordleHero} className='project__img-half' alt='gif of pokemon selection screen'/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Wordle;