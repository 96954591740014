import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import legacyHero from '../../assets/projects/legacy-hero.jpg';
import legacyPDF from '../../assets/projects/legacy-paper.pdf';
import legacyGif2 from '../../assets/projects/legacy-seq2.gif';
import legacyGif3 from '../../assets/projects/legacy-seq3.gif';
import legacyGif4 from '../../assets/projects/legacy-seq4.gif';
import legacyIllustration1 from '../../assets/projects/legacy-i1.png';
import legacyIllustration2 from '../../assets/projects/legacy-i2.png';
import legacyIllustration3 from '../../assets/projects/legacy-i3.png';
import legacyIllustration4 from '../../assets/projects/legacy-i4.png';
import legacyIllustration5 from '../../assets/projects/legacy-i5.png';
import legacyVid from '../../assets/projects/legacy-vid.mp4';

const projectSummary = {
    title: "Bike Alive: The Legacy Project",
    description: "Bike add-ons that emulate horse behaviour for emotional attachment.",
    stack: "Arduino (C++), Android Studio",
    team: "Sara Maia, Zemeng (Felix) Wang, Timothy Lee",
    role: "Design, arduino code collab",
    year: "2015",
    repo: "Not public",
    final_text: "Read the research paper",
    final_link: legacyPDF 
}

function Legacy() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={legacyHero} fit='height'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <div className='project__container'>
                        <p className='project__overview'>This project intends to reproduce the bonding between a rider and a horse through modification of a regular bicycle with several interactive behaviors. Such behaviors are triggered by environment signals generated during the cycling process & the user’s interaction with the bicycle.</p>
                        <img src={legacyIllustration4} className='project__img-half' alt='bike diagram'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>Presentation</h2>
                    <br/>
                    <video width="100%" height="500" controls>
                        <source src={legacyVid} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='project__subsection'>
                    <h2>The design</h2>
                    <div className='project__container'>
                        <img src={legacyIllustration5} className='project__img-third' alt='system diagram'/>
                    </div>
                    <ul>
                        <li>The microcontroller, and batteries are placed in a bag near the handles of the bicycle.</li>
                        <li>The Arduino is used as the microcontroller. It receives state commands from the smartphone and controls the servo motors accordingly.</li>
                        <li>The smartphone is mounted in the middle of the bicycle handles. It is used to measure, record and process the environment parameters and send the corresponding state commands to the microcontroller. It measures the speed of the bicycle, sound level, and acceleration when the bicycle is in motion. Sound level detection is replaced by Speech detection when the bicycle is stationary. Touch detection is also enabled on the screen of the phone. Other than that, the smartphone generates vibration and sound corresponding to the state of the bicycle.</li>
                        <li>The headlight will be mounted on two servo motors connected to the handlebar. The two servo motors will give the headlight a maximum movement range of 180 degrees in both altitude and azimuth.</li>
                        <li>A servo motor is mounted on the front wheel bicycle fork with a short plastic piece attached to generate the interference and sound.</li>
                    </ul>
                </div>
                <div className='project__subsection'>
                    <h2>The behaviour</h2>
                    <h3>Mood states</h3>
                    <img src={legacyIllustration1} className='project__img-full' alt='Interaction model demo photos'/>
                    <h3>Projected state diagram / implemented state diagram</h3>
                    <div className='project__container'>
                        <img src={legacyIllustration2} className='project__img-third' alt='screen gif'/>
                        <img src={legacyIllustration3} className='project__img-third' alt='screen gif'/>
                        <ol>
                            <li>When the bicycle is stationary, triggers when the bicycle’s name is mentioned or the screen is touched. When the bicycle is in motion, triggers when the screen is touched or when no other emotion is triggered for a certain duration.</li>
                            <li>When the bicycle is in motion, triggers when a sudden noise larger than the threshold is encountered.</li>
                            <li>When the bicycle is either stationary or in motion, triggers when a sudden acceleration larger than the threshold is experienced.</li>
                        </ol>
                    </div>
                    <h3>Behaviour in action</h3>
                    <div className='project__container'>
                        <img src={legacyGif2} className='project__img-third' alt='Behaviour gif'/>
                        <img src={legacyGif3} className='project__img-third' alt='Behaviour gif'/>
                        <img src={legacyGif4} className='project__img-third' alt='Behaviour gif'/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Legacy;