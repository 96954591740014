import { scryRenderedComponentsWithType } from 'react-dom/test-utils';
import './BackTop.scss';

function BackTop() {

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    
    return (
        <div className='back' onClick={handleClick}>
            <p>🡡 Top</p>
        </div>
    );
};

export default BackTop;