import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import algPlanter1 from '../../assets/projects/alg-planter1.JPG';
import algPlanter2 from '../../assets/projects/alg-planter2.JPG';
import algPlanter3 from '../../assets/projects/alg-planter3.JPG';
import algPlanter4 from '../../assets/projects/alg-planter4.jpg';
import algPlanter5 from '../../assets/projects/alg-planter5.jpg';
import algPlanter6 from '../../assets/projects/alg-planter6.jpg';
import algChair1 from '../../assets/projects/alg-chair1.jpg';
import algChair2 from '../../assets/projects/alg-chair2.jpg';

const projectSummary = {
    title: "Select algorithmic designs",
    description: "Computer programming used to generate design",
    stack: "Grasshopper(visual programming language)",
    team: "Sara Maia (solo)",
    role: "Design, program",
    year: "Various",
    repo: "N/A",
    final_text: "N/A",
    final_link: ""
}

function Algorithmic() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={algPlanter1} fit='height'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>1. Parametric concrete planter</h2>
                    <h3>Description</h3>
                    <div className='project__container'>
                        <p className='project__overview'>Project to automatically generate flat panels to form the mold for a geometric but irregular concrete planter, based on given parameters.</p>
                        <img src={algPlanter3} className='project__img-third' alt='digital planter'/>
                    </div>
                    <h3>Inputs</h3>
                    <ul>
                        <li>Seed</li>
                        <li>Number of panels</li>
                        <li>Diameter at base</li>
                        <li>Diameter at intermediary height</li>
                        <li>Diameter at top</li>
                        <li>Total height</li>
                        <li>Height of intermediary line</li>
                    </ul>
                    <h3> Visual program </h3>
                    <img src={algPlanter1} className='project__img-full' alt='Grasshopper visual code'/>
                    <h3> Digital output </h3>
                    <ul>
                        <li>Geometric shape of final planter</li>
                        <li>Numbering of each panel for fabrication</li>
                        <li>Plotting of each panel on A4 paper sheets</li>
                    </ul>
                    <img src={algPlanter2} className='project__img-full' alt='Grasshopper visual code'/>
                    <h3> The construction process</h3>
                    <div className='project__container'>
                        <img src={algPlanter4} className='project__img-third' alt='building the planter'/>
                        <img src={algPlanter5} className='project__img-third' alt='building the planter'/>
                        <img src={algPlanter6} className='project__img-third' alt='building the planter'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>2. Parametric chair</h2>
                    <h3>Description</h3>
                    <div className='project__container'>
                        <p className='project__overview'>Parametric chair with computer-generated design and 3D-printed. The chair is made-up of "cells" with an opening in the center. The number and size of cells is based on given input, and the size of the opening and based on the cells location. Cells closer to the seat area have smaller openings, growing larger the more distant they are from the seat's center.</p>
                        <img src={algChair1} className='project__img-half' alt='parametric chair'/>
                    </div>
                    <h3>Visual program</h3>
                    <img src={algChair2} className='project__img-full' alt='Grasshopper visual code'/>
                </div>
            </section>
        </main>
    );
};

export default Algorithmic;