import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import loungeHero from '../../assets/projects/lounge-main.jpg';
import loungeVid from '../../assets/projects/lounge-vid.mp4';
import loungeScreen1 from '../../assets/projects/lounge-screen1.gif';
import loungeScreen2 from '../../assets/projects/lounge-screen2.gif';
import loungeScreen3 from '../../assets/projects/lounge-screen3.gif';
import loungeIllustration1 from '../../assets/projects/lounge-i1.png';
import loungeIllustration2 from '../../assets/projects/lounge-i2.png';
import loungeIllustration3 from '../../assets/projects/lounge-i3.png';
import loungeIllustration4 from '../../assets/projects/lounge-i4.png';
import loungeModel1 from '../../assets/projects/lounge-m1.png';
import loungeModel2 from '../../assets/projects/lounge-m2.png';
import loungeModel3 from '../../assets/projects/lounge-m3.png';
import loungeModel4 from '../../assets/projects/lounge-m4.png';
import loungeModel5 from '../../assets/projects/lounge-m5.png';
import loungeModel6 from '../../assets/projects/lounge-m6.png';
import loungeModel7 from '../../assets/projects/lounge-m7.png';
import loungeModel8 from '../../assets/projects/lounge-m8.png';
import loungePhoto1 from '../../assets/projects/lounge-p1.png';
import loungePhoto3 from '../../assets/projects/lounge-p3.png';

const projectSummary = {
    title: "Interactive Lounge",
    description: "Test of concept for an interactive space with 4 distinct modes of interaction.",
    stack: "Python, OpenCV, Javascript, Paper.js, projection mapping(VP7), Syphon, websockets",
    team: "Sara Maia (sole)",
    role: "Design, architecture, code",
    year: "2016",
    repo: "https://github.com/scmaia/2D-tracking-in-space",
    final_text: "Read the thesis",
    final_link: "https://open.library.ubc.ca/soa/cIRcle/collections/ubctheses/24/items/1.0305043" 
}

function InteractiveRoom() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={loungeHero} fit='height'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <div className='project__container'>
                        <p className='project__overview'>Working prototype of interactive architecture represented by projected interior partitions. The prototype was fully designed, constructed and programmed by myself as part of my Masters thesis research. The protoype included application to track users in space, applications to generate partition graphics, application to manage frames for projection, among others.</p>
                        <img src={loungeIllustration1} className='project__img-half' alt='overview of room setup'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>Demo</h2>
                    <br/>
                    <video width="100%" height="500" controls>
                        <source src={loungeVid} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='project__subsection'>
                    <h2>The tracking</h2>
                    <p>I created a tracking apparatus inspired by Playstation's controllers. I used python and the Computer Vision library OpenCV. The initial code for this application can be found here for reference: <a href='https://github.com/scmaia/2D-tracking-in-space'>https://github.com/scmaia/2D-tracking-in-space</a></p>
                    <img src={loungeIllustration2} className='project__img-full' alt='diagram of tracking apparatus'/>
                </div>
                <div className='project__subsection'>
                    <h2>The virtual partitions</h2>
                    <div className='project__container'>
                        <img src={loungeScreen3} className='project__img-half' alt='screen gif'/>
                        <p className='project__overview'>I built a web application using vanilla Javascript and Paper.js to generate the graphics for the projected partitions. The application had two modes: one that allowed for manual drawing and manipulation of the graphics, and one that generated organic, voronoi-inspired graphics automatically based on number and location of users.</p>
                    </div>
                    <div className='project__container'>
                        <img src={loungeIllustration4} className='project__img-third' alt='Behaviour logic'/>
                        <img src={loungeScreen1} className='project__img-third' alt='screen gif'/>
                        <img src={loungeScreen2} className='project__img-third' alt='screen gif'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>The projection</h2>
                    <img src={loungeIllustration3} className='project__img-full' alt='Interaction model demo photos'/>
                    <div className='project__container'>
                        <p className='project__overview'>I built a special mount for the two short-throw projectors. The mounts used mirrors to allow the projectors to be wall-mounted in horizontal position but still project on the floor. Image frames were pre-split, pre-distoted and 'mirrored' to compensate for the projectors' arrangement.</p>
                        <img src={loungePhoto3} className='project__img-half' alt='photo of projector setup'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>The behaviour</h2>
                    <div>
                        <h3>Approach</h3>
                        <ul>
                            <li>The apparatus was built in order to test user experience of interactive architecture according to 4 different interaction models.</li>
                            <li>3 of the models (self-adjustment, Direct Manipulation and Human like) were emulated using an HCI technique called 'the wizard of Oz'. This means that I was manipulating the graphics directly behind the scenes according to user input & behaviour, instead of building sofisticated AI to conduct the task. </li>
                            <li>The forth interaction model (Emergent Behaviour) could not be emulated using 'wizard of Oz', thus I fully programmed this model to respond to users locations without other human inputs.</li>
                        </ul>
                        <h3>Interaction model: Self-adjustment</h3>
                        <img src={loungeModel1} className='project__img-full' alt='Interaction model diagram'/>
                        <img src={loungeModel2} className='project__img-full' alt='Interaction model demo photos'/>
                        <h3>Interaction model: Direct Manipulation</h3>
                        <img src={loungeModel3} className='project__img-full' alt='Interaction model diagram'/>
                        <img src={loungeModel4} className='project__img-full' alt='Interaction model demo photos'/>
                        <h3>Interaction model: Human-like</h3>
                        <img src={loungeModel5} className='project__img-full' alt='Interaction model diagram'/>
                        <img src={loungeModel6} className='project__img-full' alt='Interaction model demo photos'/>
                        <h3>Interaction model: Emergent behaviour</h3>
                        <img src={loungeModel7} className='project__img-full' alt='Interaction model diagram'/>
                        <img src={loungeModel8} className='project__img-full' alt='Interaction model demo photos'/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default InteractiveRoom;