import './Ball.scss';
import React, {useState, useEffect, useRef} from 'react';

let ball;
let bar;
let flippers;
let portfolioItems;

function Ball({ barRotation, hoveredItem, updateHitList, leftActivated, rightActivated, filteredProjects }) {
    const [coordX, setCoordX] = useState(150);
    const [coordY, setCoordY] = useState(0);
    const [velX, setVelX] = useState(0)
    const [velY, setVelY] = useState(0.01)
    const [pageHeight, setPageHeight] = useState(0);

    //initiate
    useEffect( () => {
        setPageHeight(document.documentElement.scrollHeight);
        ball = document.getElementById('ball');
        bar = document.getElementById('bar');
        portfolioItems = document.getElementsByClassName('item');
        flippers = [document.getElementById('flipper-left'), document.getElementById('flipper-right')]
    }, [])

    useEffect( () => {
        setPageHeight(document.documentElement.scrollHeight);
    }, [filteredProjects])

    //reset initiate on page resize

    //animation
    useInterval(() => {
        //gravity
        setVelY(velY + 0.03)

        //move
        setCoordY(coordY + velY)
        setCoordX(coordX + velX)

        //ball interaction with page edges
        if (coordY >= pageHeight) {
            setCoordY(0);
            setCoordX(150);
            setVelY(0.1);
            setVelX(0);
        }
        if (coordY <= 0) {
            setVelY(Math.abs(velY));
        }
        if (coordX <= 0) {
            setVelX(Math.abs(velX));
        }
        if (coordX >= document.documentElement.clientWidth) {
            setVelX(-Math.abs(velX));
        }

        //ball interaction with portfolio items
        const activePortfolioItems = [...portfolioItems].filter(item => item !== hoveredItem);
        activePortfolioItems.forEach(item => {
            const overlapResult = circlesOverlap(item, ball);
            if (overlapResult) {
                setVelX(overlapResult.xVector*5);
                setVelY(overlapResult.yVector*5);
                updateHitList(item);
            } else {
                return;
            }
        });

        //ball interaction with bar
        // if (barOverlap(ball)) {
        //     setVelY((-0.3 * bar.getBoundingClientRect().width / bar.getBoundingClientRect().height) - 3)
        //     if ((barRotation % 360) === 0) {
        //         return;
        //     } else if (((barRotation % 360) > 0 && (barRotation % 360) < 90) || ((barRotation % 360) > 270 && (barRotation % 360) < 360)) {
        //         setVelX(2 * bar.getBoundingClientRect().height / bar.getBoundingClientRect().width)
        //     } else {
        //         setVelX(-2 * bar.getBoundingClientRect().height / bar.getBoundingClientRect().width)
        //     }
        // }

        //ball interaction with flippers
        if (flippersOverlap(ball, flippers[0], false)) {
            if (leftActivated) {
                setVelY(-Math.abs(velY * 1.4));
                setVelX(velX + 0.1)
            } else {
                setVelY(-2);
                setVelX(velX + 0.1)
            }

        }

        if (flippersOverlap(ball, flippers[1], true)) {
            if (rightActivated) {
                setVelY(-Math.abs(velY * 1.4));
                setVelX(velX - 0.1)
            } else {
                setVelY(-2);
                setVelX(velX - 0.1)
            }
        }

    }, 10);

    //function returns x & y vectors of contact if two circular elements hit
    const circlesOverlap = (el1, el2) => {
        const domRect1 = el1.getBoundingClientRect();
        const domRect2 = el2.getBoundingClientRect();
        const r1 = domRect1.width/2;
        const x1 = domRect1.left + r1;
        const y1 = domRect1.top + r1;
        const r2 = domRect2.width/2;
        const x2 = domRect2.left + r2;
        const y2 = domRect2.top + r2;

        if (Math.hypot(x1 - x2, y1 - y2) <= r1 + r2) {
            // return Math.atan2(y1 - y2, x1 - x2);
            return {xVector: (x2 - x1)/(r1 + r2), yVector: (y2 - y1)/(r1 + r2)};
        } else {
            return null;
        }
    }

    //function returns whether element hit bar
    // const barOverlap = (el) => {
    //     const elRect = el.getBoundingClientRect();
    //     const barRect = bar.getBoundingClientRect();
    //     const isClear = elRect.top > barRect.bottom || elRect.bottom < barRect.top || elRect.right < barRect.left || elRect.left > barRect.right;

    //     if(isClear) {
    //         return false;
    //     } else {
    //         const offsetFactor = (elRect.left - barRect.left) / barRect.width;
    //         const barIsFoward = ((barRotation % 360) > 0 && (barRotation % 360) < 90) || ((barRotation % 360) > 270 && (barRotation % 360) < 360);
    //         const trueBottom = barIsFoward ? barRect.top + (barRect.height * offsetFactor) : barRect.top + (barRect.height * (1 - offsetFactor));

    //         if(trueBottom < elRect.bottom) {
    //             return true
    //         } else {
    //             return false;
    //         }
    //     }
    // }

    //function returns whether element hit flippers
    const flippersOverlap = (el, flipper, inverted, activated) => {
        const elRect = el.getBoundingClientRect();
        const flipperRect = flipper.getBoundingClientRect();
        const isClear = elRect.top > flipperRect.bottom || elRect.bottom < flipperRect.top || elRect.right < flipperRect.left || elRect.left > flipperRect.right;

        if(isClear) {
            return false;
        } else {
            const offsetFactor = (elRect.left - flipperRect.left) / flipperRect.width;
            const trueBottom = inverted ? flipperRect.top + (flipperRect.height * (1 - offsetFactor)) - (flipperRect.height/5) : flipperRect.top + (flipperRect.height * offsetFactor) - (flipperRect.height/5);

            if(trueBottom < elRect.bottom) {
                return true
            } else {
                return false;
            }
        }
    }

    return (
        <div className='ball' id='ball' style={{top: coordY, left: coordX}}></div>
    );
};

//custom hook
function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}

export default Ball;