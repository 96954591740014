import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import archHero from '../../assets/projects/arch-main.JPG';


const projectSummary = {
    title: "Architectural portfolio",
    description: "Select works in architecture, interiors and urban design",
    stack: "Hand sketching, AutoCAD, Revit, Sketchup, Adobe Suite, Rhino",
    team: "Sara Maia",
    role: "Architect (CAU, Brazil), Intern Architect (AIBC, Canada)",
    year: "2006-present",
    repo: "N/A",
    final_text: "2016 portfolio",
    final_link: "https://drive.google.com/file/d/1ZuExohzvwDe94VvbfYOEr7JCbg4xrvXc/view?usp=sharing"
}

function Architecture() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={archHero} fit='width'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>2006-2016</h2>
                    <iframe src="https://drive.google.com/file/d/1ZuExohzvwDe94VvbfYOEr7JCbg4xrvXc/preview" width="100%" height="600" allow="autoplay"></iframe>
                </div>
                <div className='project__subsection'>
                    <h2>2017-Present</h2>
                    <p>5 years experience with Tenant Improvements across Canada.</p>
                    <p>Updated portfolio in underway!</p>
                </div>
            </section>
        </main>
    );
};

export default Architecture;