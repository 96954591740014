import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import capstoneHero from '../../assets/projects/capstone-main.png';
import capstoneImg1 from '../../assets/projects/capstone1.gif';
import capstoneImg2 from '../../assets/projects/capstone2.gif';
import capstoneImg3 from '../../assets/projects/capstone3.gif';
import capstoneImg4 from '../../assets/projects/capstone4.png';
import capstoneImg5 from '../../assets/projects/capstone5.png';
import capstoneImg6 from '../../assets/projects/capstone6.png';
import capstoneImg7 from '../../assets/projects/capstone7.png';
import capstoneVid from '../../assets/projects/capstone-vid.mp4';

const projectSummary = {
    title: "NextBlock.Shop",
    description: "E-commerce platform",
    stack: "React, Node, Express, MySQL, Knex",
    team: "Sara Maia (sole)",
    role: "Design, architecture, code",
    year: "2022",
    repo: "Not public",
    final_text: "http://nextblock.shop",
    final_link: "http://nextblock.shop" 
}

function Capstone() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={capstoneHero} fit='width'/>
            <section className='project__section'>
                <h2>Overview</h2>
                <div className='project__container'>
                    <p className='project__overview'>Next Block Shop is a web platform for small businesses and individuals with side-hustles to offer their services and products in their local communities.
It allows vendors to create simple webpages to manage online offers and orders easily, and it gives them the tools to make their businesses work around their lives.</p>
                    <img src={capstoneImg4} className='project__img-half' alt='page mockup on laptop and mobile'/>
                </div>
                <h2>Demo</h2>
                <br/>
                <video width="100%" height="500" controls>
                    <source src={capstoneVid} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                <h2>Know more</h2>
                <div>
                    <h3>Features</h3>
                    <ul>
                        <li>Create simple webpages with custom slug</li>
                        <li>Extremely low entry barrier and learning curve for new vendors</li>
                        <li>Calendar based component to manage availability</li>
                        <li>Set customizable restrictions for each page and product</li>
                        <li>Email-based notifications for vendors and buyers</li>
                        <li>Easy-to-use dashboard for reviewing, approving or rejecting orders</li>
                        <li>Minimalistic page template to avoid conflict with vendors' branding</li>
                        <li>Fully responsive design</li>
                    </ul>
                    <h3>Website map</h3>
                    <img src={capstoneImg6} className='project__img-full' alt='website map'/>
                    <h3>Relational database</h3>
                    <img src={capstoneImg5} className='project__img-full' alt='database schema'/>
                    <h3>Initial wireframes</h3>
                    <img src={capstoneImg7} className='project__img-full' alt='dashboard wireframe'/>
                </div>
                <div>
                    <h2>Quick Peek</h2>
                    <img src={capstoneImg1} className='project__img-third' alt='initial page demo'/>
                    <img src={capstoneImg2} className='project__img-third' alt='dashboard page demo'/>
                    <img src={capstoneImg3} className='project__img-third' alt='vendor page demo'/>
                </div>
            </section>
        </main>
    );
};

export default Capstone;