import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import pokeHero from '../../assets/projects/poke-g2.gif';
import pokeGif1 from '../../assets/projects/poke-g1.gif';
import pokeGif3 from '../../assets/projects/poke-g3.gif';

const projectSummary = {
    title: "Drunk Pokemon Battle",
    description: "24 Hackaton project. The requirement? Use any open RESTful API of choice. We chose to mix pokeapi.co & thecocktaildb.com",
    stack: "Vanilla Javascript, HTML DOM API, axios",
    team: "Sara Maia, Nick Cascella & Kirill Tchentsov",
    role: "Design, code collab",
    year: "2022",
    repo: "https://github.com/scmaia/mini-hackathon-one",
    final_text: "https://drunk-pokemon.netlify.app/",
    final_link: "https://drunk-pokemon.netlify.app/" 
}

function Pokemon() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={pokeHero} fit='height'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <div className='project__container'>
                        <p className='project__overview'>This project is a fun game where one pokemon attempts to get the other drunk by choosing cocktail drinks that start with the first letter of its name. It was a great collaboration effort with 3 people working simultaneously in the same code base. Great team, great communication, a lot of fun!</p>
                        <img src={pokeGif3} className='project__img-half' alt='gif of pokemon selection screen'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>Sneak Peek</h2>
                    <img src={pokeGif1} className='project__img-full' alt='gif of pokemon battle'/>
                </div>
            </section>
        </main>
    );
};

export default Pokemon;