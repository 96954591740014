import './Menu.scss';
import CV from '../../assets/files/Sara_Maia_2022_CV.pdf'
import avatar from '../../assets/images/sara-cooler2.png';
import backArrow from '../../assets/icons/arrow_back-24px.svg';
import AboutItem from '../AboutItem/AboutItem';
import MenuItem from '../MenuItem/MenuItem';
import Ball from '../Ball/Ball';
// import Bar from '../Bar/Bar';
import Flippers from '../Flippers/Flippers';
import Header from '../Header/Header';
import { projectList, projectFilters } from './projectList';
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';

const ALL_PROJECT_FILTERS = projectFilters;

function Menu() {

    const [hoveredItem, setHoveredItem] = useState(null)
    const [barRotation, setBarRotation] = useState(30);
    const [hitItems, setHitItems] = useState([]);
    const [filter, setFilter] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const currentPath = useLocation().pathname;

    const [leftActivated, setLeftActivated] = useState(false);
    const [rightActivated, setRightActivated] = useState(false);

    useEffect( () => {
        window.addEventListener('keydown', (event) => {
            handleShift(event.code);
        });
        setFilteredProjects(projectList)
    }, [])

    const handleShift = (key) => {
        if (key === 'ShiftLeft'){
            setLeftActivated(true)
            setTimeout( () => {
                setLeftActivated(false)
            }, 300)
        }
        if (key === 'ShiftRight'){
            setRightActivated(true)
            setTimeout( () => {
                setRightActivated(false)
            }, 300)
        }
    }

    const handleFilter = (selectedFilter) => {
        setFilter(selectedFilter);
        if (selectedFilter) {
            setFilteredProjects(projectList.filter(project => project.stack.includes(selectedFilter)));
        } else {
            setFilteredProjects(projectList);
        };
    } 

    const updateHitList = (hitItem) => {
        if (hitItems.includes(hitItem.id)) {
            return;
        } else {
            let updatedList = [...hitItems];
            updatedList.push(hitItem.id);
            setHitItems(updatedList);
        }
    }

    return (
        <main>
            {currentPath === '/' &&
                <>
                    <Header totalPins={projectList.length} hitPins={hitItems.length} cv={CV}/>
                    <div className='menu__filters'>
                        <p className={`menu__filter ${filter ? "menu__filter--inactive" : "menu__filter--active"}`} onClick={() => handleFilter('')} key='all'>All</p>
                        {ALL_PROJECT_FILTERS.map(filterInstance => 
                            <p className={`menu__filter ${filter ? filterInstance === filter ? "menu__filter--active" : "menu__filter--inactive" : ""}`} onClick={() => handleFilter(filterInstance)} key={filterInstance}>{filterInstance}</p>
                        )}
                    </div>
                </>
            }
            <section className='menu' id='menu'>
                <Link to='/' className={`menu__back ${currentPath === '/' ? 'menu__back--list' : undefined}`}><img src={backArrow} alt='return to home'/></Link>
                <div className={`menu__container ${currentPath !== '/' ? 'menu__container--list' : undefined}`}>
                    {currentPath === '/'
                    // ? <Bar barRotation={barRotation} setBarRotation={setBarRotation} avatar={avatar} cv={CV}/>
                    ? <></>
                    : <AboutItem avatar={avatar} cv={CV}/>            
                    }
                    {filteredProjects.map( project =>
                        <Link to={project.slug} key={project.id} onClick={() => handleFilter('')}>
                            <MenuItem 
                                image={project.image}
                                title={project.title}
                                description={project.description}
                                descriptionExtended={project.descriptionExtended}
                                setHoveredItem={setHoveredItem}
                                id={project.id}
                                hit={hitItems.includes(project.id)}
                            />
                        </Link>
                    )}
                </div>
            </section>
            {currentPath === '/' &&
                <>
                <Flippers leftActivated={leftActivated} rightActivated={rightActivated}/>
                <Ball barRotation={barRotation} hoveredItem={hoveredItem} updateHitList={updateHitList} leftActivated={leftActivated} rightActivated={rightActivated} filteredProjects={filteredProjects}/>
                </>
            }
        </main>
    );
};

export default Menu;