import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import evHero from '../../assets/projects/ev-main.jpg';
import evImg1 from '../../assets/projects/ev-1.webp';
import evImg2 from '../../assets/projects/ev-2.JPG';
import evImg3 from '../../assets/projects/ev-3.jpg';
import evImg4 from '../../assets/projects/ev-4.png';
import evImg5 from '../../assets/projects/ev-5.jpg';
import evImg6 from '../../assets/projects/ev-6.jpg';

const projectSummary = {
    title: "Smart microgrid interface",
    description: "Implementation of an EV charging interface design as part of research on grid optimization",
    stack: "",
    team: "Sara Maia, others",
    role: "Project lead",
    year: "2014",
    repo: "N/A",
    final_text: "Not published",
    final_link: ""
}

function EV() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={evHero} fit='width'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <div className='project__container'>
                        <p className='project__overview'>We created a smart charge system for EVs to test how different interfaces increased user adoption of green charge management features. This project is part of a larger research on EV charging & green energy alignment. It was deployed at BCIT's Energy Oasis for testing.</p>
                        <img src={evImg1} className='project__img-half' alt='rendering'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>The larger picture</h2>
                    <div className='project__container'>
                        <div>
                            <p>Increase in EV fleet is expected to add stress to peak electrical consumption hours. Ideally, the extra load should be covered by low-emissions renewable energy sources. These energy sources are also typically non-dispatchable (i.e. not flexible towards demand, such as wind or run-of-river). EV charge demand, however, could be made flexible to improve alignment with non-dispatchable availability.</p>
                            <br/>
                            <p>The larger project looked at different ways we could nudge users to engage with charge management systems in order to consume greener energy. </p>
                        </div>
                        <img src={evImg2} className='project__img-half' alt='charging station example'/>
                    </div>
                    <br/>
                    <img src={evImg3} className='project__img-full' alt='charging station with AR proposal'/>
                </div>
                <div className='project__subsection'>
                    <h2>The prototype implemented at BCIT campus</h2>
                    <h3>System logic</h3>
                    <img src={evImg4} className='project__img-full' alt='charging station with AR proposal'/>
                    <h3>Interfaces</h3>
                    <img src={evImg5} className='project__img-full' alt='charging station with AR proposal'/>
                    <img src={evImg6} className='project__img-full' alt='charging station with AR proposal'/>
                </div>
            </section>
        </main>
    );
};

export default EV;