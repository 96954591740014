import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import vrHero from '../../assets/projects/unity-main.png';
import vrImg1 from '../../assets/projects/unity-1.png';
import vrImg2 from '../../assets/projects/unity-2.png';
import vrImg3 from '../../assets/projects/unity-3.png';
import vrImg4 from '../../assets/projects/unity-4.png';


const projectSummary = {
    title: "Interactive Brise Soleil in VR",
    description: "Virtual Reality project to test users inetraction with a responsive Brise Soleil system.",
    stack: "Unity engine, Oculus SDK",
    team: "Sara Maia (solo)",
    role: "Design, Unity development, Oculus testing",
    year: "2015",
    repo: "N/A",
    final_text: "Not published",
    final_link: ""
}

function VR() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={vrHero} fit='height'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Overview</h2>
                    <div className='project__container'>
                        <p className='project__overview'>This project created a responsive brise soleil system in Virtual Reality to test inhabitant's use experience of such a system. The virtual reality and responsive system was fully developed in Unity and deployed using Oculus (then Rift) SDK. The experiment did not proceed because preliminary testing highlighted issues with vertigo and with challanges transposing findings in VR into real world architectural experience.</p>
                        <img src={vrImg4} className='project__img-half' alt='rendering'/>
                    </div>
                </div>
                <div className='project__subsection'>
                    <h2>The brise soleil system</h2>
                    <div className='project__container'>
                        <img src={vrImg1} className='project__img-third' alt='concept diagram'/>
                        <img src={vrImg2} className='project__img-third' alt='concept diagram'/>
                    </div>
                    <div className='project__container'>
                        <img src={vrImg3} className='project__img-half' alt='concept diagram'/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default VR;