import './projects.scss';
import Hero from '../../Components/Hero/Hero';
import pinballHero from '../../assets/projects/portfolio-main.JPG';
import vrImg1 from '../../assets/projects/unity-1.png';
import vrImg2 from '../../assets/projects/unity-2.png';
import vrImg3 from '../../assets/projects/unity-3.png';
import vrImg4 from '../../assets/projects/unity-4.png';


const projectSummary = {
    title: "Pinball-inspired Portfolio page",
    description: "You saw it :)",
    stack: "React",
    team: "Sara Maia (solo)",
    role: "Design, code",
    year: "2022",
    repo: "Not public",
    final_text: "http://saramaia.me",
    final_link: "http://saramaia.me"
}

function Pinball() {
    return (
        <main className='project__main'>
            <Hero summary={projectSummary} image={pinballHero} fit='width'/>
            <section className='project__section'>
                <div className='project__subsection'>
                    <h2>Sneak Peek: Bauncing ball code</h2>
                    <pre><code>
                        {`let ball;
let portfolioItems;

function Ball({ hoveredItem, updateHitList }) {
    const [coordX, setCoordX] = useState(150);
    const [coordY, setCoordY] = useState(0);
    const [velX, setVelX] = useState(0)
    const [velY, setVelY] = useState(0.01)
    const [pageHeight, setPageHeight] = useState(0);

    //initiate
    useEffect( () => {
        setPageHeight(document.documentElement.scrollHeight);
        ball = document.getElementById('ball');
        portfolioItems = document.getElementsByClassName('item');
    }, [])

    //animation
    useInterval(() => {
        //gravity
        setVelY(velY + 0.03)

        //move
        setCoordY(coordY + velY)
        setCoordX(coordX + velX)

        //ball interaction with page edges
        if (coordY >= pageHeight) {
            setCoordY(0);
            setCoordX(150);
            setVelY(0.1);
            setVelX(0);
        }
        if (coordX <= 0) {
            setVelX(Math.abs(velX));
        }
        if (coordX >= document.documentElement.clientWidth) {
            setVelX(-Math.abs(velX));
        }

        //ball interaction with portfolio items
        const activePortfolioItems = [...portfolioItems].filter(item => item !== hoveredItem);
        activePortfolioItems.forEach(item => {
            const overlapResult = circlesOverlap(item, ball);
            if (overlapResult) {
                setVelX(overlapResult.xVector*3);
                setVelY(overlapResult.yVector*3);
                updateHitList(item);
            } else {
                return;
            }
        });
    }, 10);

    //function returns x & y vectors of contact if two circular elements hit
    const circlesOverlap = (el1, el2) => {
        const domRect1 = el1.getBoundingClientRect();
        const domRect2 = el2.getBoundingClientRect();
        const r1 = domRect1.width/2;
        const x1 = domRect1.left + r1;
        const y1 = domRect1.top + r1;
        const r2 = domRect2.width/2;
        const x2 = domRect2.left + r2;
        const y2 = domRect2.top + r2;

        if (Math.hypot(x1 - x2, y1 - y2) <= r1 + r2) {
            return {xVector: (x2 - x1)/(r1 + r2), yVector: (y2 - y1)/(r1 + r2)};
        } else {
            return null;
        }
    }

    return (
        <div className='ball' id='ball' style={{top: coordY, left: coordX}}></div>
    );
};

//custom hook
function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}

export default Ball;`}
                    </code></pre>
                </div>
            </section>
        </main>
    );
};

export default Pinball;