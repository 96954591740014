import './Hero.scss';
import BackTop from '../BackTop/BackTop';
import React, {useState, useEffect} from 'react';

let menuHeight;
const initialOpeningSize = document.documentElement.clientWidth * 0.47;

function Hero({ summary, image, fit }) {
    const [openingSize, setopeningSize] = useState(initialOpeningSize);
    const [openingCenter, setopeningCenter] = useState([30, 30]);
    const [isPastMenu, setIsPastMenu] = useState(false);

    useEffect( () => {
        window.scrollTo({
            top: 0,
        });
    }, [])

    window.addEventListener('scroll', function() {
        if (this.scrollY > document.documentElement.clientHeight) {
            menuHeight = document.getElementById('menu').clientHeight;
            if (document.documentElement.scrollTop > menuHeight - 300) {
                setIsPastMenu(true);
            } else {
                setIsPastMenu(false);
            }
        }
        else {
            let newOpeningSize = initialOpeningSize + this.scrollY*2;
            setopeningSize(newOpeningSize);
            setopeningCenter([(initialOpeningSize/2) + 30 - (newOpeningSize/2), (initialOpeningSize/2) + 30 - (newOpeningSize/2)])
        }
    });

    return (
        <div className={`hero ${fit === 'width' ? `hero__fit-width` : `hero__fit-height`}`} style={{backgroundImage: `url(${image})`}}>
            <div className='hero__curtain-box'>
                <div className='hero__curtain' style={{width: openingSize, height: openingSize, bottom: openingCenter[1], right: openingCenter[0]}}/>
            </div>
            <div className='hero__info'>
                {/* <h2>___</h2> */}
                <br/>
                <h1>{summary.title}</h1>
                <div className='hero__details'>
                    <p className='hero__description'>{summary.description}</p>
                    <p>Key tech stack: {summary.stack}</p>
                    <p>Team: {summary.team}</p>
                    <p>Role: {summary.role}</p>
                    <p>Year: {summary.year}</p>
                    {summary.repo === "Not public"
                    ? <p>Repo: Not public - available on request</p>
                    : <p>Repo: <a href={summary.repo} target="_blank" rel="noreferrer" className='hero__link'>GitHub</a></p>
                    }
                    <p>Final Project: <a href={summary.final_link} target="_blank" rel="noreferrer" className='hero__link'>{summary.final_text}</a></p>
                </div>
            </div>
            {isPastMenu && <BackTop/>}
        </div>
    );
};

export default Hero;